"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpotifyPlayerStrategy = void 0;
class SpotifyPlayerStrategy {
    constructor() {
        this.events = {}; // 使用接口中的事件管理器代替分散的回調
        this.accessToken = null;
        this.player = null;
        this.isInitialized = false;
        // after init()
        this.deviceId = null;
        this.trackInfo = null;
        // 自行製作一個 timeup event
        this.progressUpdateInterval = null;
    }
    loadSpotifySDK() {
        return new Promise((resolve, reject) => {
            if (window.Spotify) {
                console.log("Spotify SDK 已加載");
                resolve();
            }
            else {
                window.onSpotifyWebPlaybackSDKReady = () => {
                    console.log("Spotify SDK 加載完成並觸發初始化");
                    resolve();
                };
                const script = document.createElement("script");
                script.src = "https://sdk.scdn.co/spotify-player.js";
                script.onload = () => console.log("Spotify SDK 加載成功");
                script.onerror = () => reject("Spotify SDK 加載失敗");
                document.head.appendChild(script);
                console.log("正在加載 Spotify Web Playback SDK...");
            }
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.accessToken = localStorage.getItem("spotify_access_token");
            if (!this.accessToken) {
                console.error("Spotify access token 未找到，無法初始化播放器");
                return;
            }
            yield this.loadSpotifySDK(); // 確保 SDK 加載完成
            this.initializePlayer();
        });
    }
    initializePlayer() {
        if (!this.accessToken)
            return;
        this.player = new window.Spotify.Player({
            name: "Music Notion",
            getOAuthToken: (cb) => { cb(this.accessToken); },
            volume: 0.5
        });
        this.player.addListener("ready", ({ device_id }) => {
            console.log("Spotify 播放器已準備好，裝置 ID:", device_id);
            this.deviceId = device_id;
            this.isInitialized = true;
            this.addSpotifyEventListeners(); // 自製的listener
        });
        this.player.connect().then((success) => {
            if (success) {
                console.log("Spotify Web Player 成功連接");
            }
            else {
                console.error("Spotify Web Player 連接失敗");
            }
        });
    }
    checkInitialized() {
        if (!this.isInitialized) {
            console.log("sss");
            this.init();
        }
    }
    uploadNewTrack() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            console.log("更新 Spotify 播放器狀態");
            if (!this.isInitialized || !this.player) {
                console.warn("Spotify 播放器尚未初始化或未連接");
                return true;
            }
            const trackId = prompt("Spotify Player Mode：輸入曲目 ID。(Search 頁面正在建置中，暫時需手動查詢官方 Track ID 後輸入)\n\n（例如網址 https://open.spotify.com/track/1QlTNF43OyKK9BorIRsJ8o?si=f2798859318a4672 取track/後的字串）\n\n測試代碼： \n1QlTNF43OyKK9BorIRsJ8o?si=f2798859318a4672");
            if (!trackId) {
                console.log("未輸入 ID，取消操作");
                return false;
            }
            try {
                const response = yield fetch(`https://api.spotify.com/v1/tracks/${trackId}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
                if (!response.ok) {
                    console.error("查詢曲目資訊失敗，請檢查 ID 是否正確: " + trackId);
                    return false; // 如果 API 查詢失敗，返回 false
                }
                const data = yield response.json();
                this.trackInfo = {
                    id: data.id,
                    name: data.name,
                    duration: data.duration_ms,
                    artist: (_b = (_a = data.artists) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.name,
                    album: (_c = data.album) === null || _c === void 0 ? void 0 : _c.name
                };
                (_e = (_d = this.events).onTrackChange) === null || _e === void 0 ? void 0 : _e.call(_d, this.trackInfo);
                this.trackInfo = data;
                this.trackInfoToPlayer();
                return true;
            }
            catch (error) {
                console.error("獲取曲目資訊時發生錯誤:", error);
                return false; // 捕捉到錯誤時返回 false
            }
            // 預設返回 false，以防任何漏網的情況
            return false;
        });
    }
    trackInfoToPlayer() {
        if (!this.deviceId) {
            console.warn("裝置 ID 未設定，無法將曲目設置到播放器");
            return;
        }
        if (!this.trackInfo || !this.trackInfo.uri) {
            console.warn("trackInfo 未設定或缺少 URI，無法設置播放內容");
            return;
        }
        if (!this.player) {
            console.error("播放器尚未初始化");
            return;
        }
        console.log(this.deviceId);
        this.player.activateElement()
            .then(() => {
            console.log("播放器已啟動，準備設置播放內容");
            return fetch(`https://api.spotify.com/v1/me/player/play?device_id=${this.deviceId}`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    uris: [this.trackInfo.uri]
                })
            });
        })
            .then((response) => {
            if (response.ok) {
                console.log("成功將曲目設置到播放器:", this.trackInfo.name);
            }
            else {
                return response.json().then((errorData) => {
                    console.error("設置播放內容失敗:", errorData);
                });
            }
        })
            .catch((error) => {
            console.error("設置播放內容時發生錯誤:", error);
        });
    }
    isReadyToPlay() {
        return Promise.resolve(this.isInitialized && this.player !== null);
    }
    play(fromStart = false) {
        if (!this.isInitialized) {
            console.warn("播放器尚未初始化，無法播放");
            return;
        }
        if (!this.deviceId) {
            console.warn("裝置 ID 未設定，無法將曲目設置到播放器");
            return;
        }
        const requestBody = fromStart && this.trackInfo && this.trackInfo.uri
            ? { uris: [this.trackInfo.uri] }
            : {}; // 如果 fromStart 為 false，則不包含 uris，從上次播放位置繼續
        // 發送播放請求
        fetch(`https://api.spotify.com/v1/me/player/play?device_id=${this.deviceId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
            if (response.ok) {
                console.log(fromStart ? "成功開始播放曲目:" : "成功從暫停位置繼續播放", this.trackInfo ? this.trackInfo.name : "");
                this.startProgressUpdater(); // 啟動進度更新
            }
            else {
                return response.json().then(errorData => {
                    console.error("播放曲目失敗:", errorData);
                });
            }
        })
            .catch(error => {
            console.error("播放曲目時發生錯誤:", error);
        });
    }
    pause() {
        if (!this.isInitialized) {
            console.warn("播放器尚未初始化，無法暫停");
            return;
        }
        if (!this.deviceId) {
            console.warn("裝置 ID 未設定，無法暫停播放");
            return;
        }
        // 發送暫停請求
        fetch(`https://api.spotify.com/v1/me/player/pause?device_id=${this.deviceId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
                "Content-Type": "application/json"
            }
        })
            .then(response => {
            if (response.ok) {
                this.stopProgressUpdater(); // 停止進度更新
                console.log("成功暫停播放");
            }
            else {
                return response.json().then(errorData => {
                    console.error("暫停播放失敗:", errorData);
                });
            }
        })
            .catch(error => {
            console.error("暫停播放時發生錯誤:", error);
        });
    }
    // Spotify ios safari didn't support Volume control
    setVolume(volume) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.player) {
                console.error('Spotify player is not initialized.');
                return;
            }
            this.player.setVolume(volume).then(() => {
                console.log(`Volume updated to ${volume * 100}%`);
            }).catch((error) => {
                if (error instanceof Error) {
                    console.error('Error occurred:', error.message);
                }
                else {
                    console.error('Unknown error occurred:', error);
                }
            });
        });
    }
    getCurrentTime() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.player) {
                console.error('Spotify player is not initialized.');
                return 0;
            }
            try {
                const state = yield this.player.getCurrentState();
                if (state && state.position !== undefined) {
                    console.log(`Current position: ${state.position} ms`);
                    return state.position; // 保留毫秒單位
                }
                else {
                    console.warn('No valid playback state available.');
                    return 0;
                }
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error('Error while fetching current time:', error.message);
                }
                else {
                    console.error('Unknown error occurred while fetching current time:', error);
                }
                return 0;
            }
        });
    }
    getDuration() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.player) {
                console.error('Spotify player is not initialized.');
                console.log("a");
                return 0;
            }
            console.log("-------------------------------------------------------------------");
            try {
                let state = yield this.player.getCurrentState();
                if (!state) {
                    console.warn('Player state is null. Retrying...');
                    yield new Promise(resolve => setTimeout(resolve, 500)); // 等待 500ms
                    state = yield this.player.getCurrentState(); // 再次嘗試獲取
                }
                if (state && state.track_window && state.track_window.current_track) {
                    console.log("b");
                    return state.track_window.current_track.duration_ms; // 返回毫秒
                }
                console.log("c");
                console.warn('No track information available in current state.');
                return 0; // 如果無法獲取狀態，返回 0
            }
            catch (error) {
                console.log("d");
                console.error('Failed to get Spotify duration:', error);
                return 0; // 出錯時返回 0
            }
        });
    }
    seekTo(time) {
        if (!this.player) {
            console.error('Spotify player is not initialized.');
            return;
        }
        this.player.seek(time)
            .then(() => {
            console.log(`Seeked to ${time} seconds`);
        })
            .catch((error) => {
            if (error instanceof Error) {
                console.error('Error while seeking:', error.message);
            }
            else {
                console.error('Unknown error occurred while seeking:', error);
            }
        });
    }
    getTrackInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.trackInfo;
        });
    }
    startProgressUpdater() {
        if (this.progressUpdateInterval !== null) {
            return; // 如果定時器已存在，直接返回
        }
        this.progressUpdateInterval = window.setInterval(() => __awaiter(this, void 0, void 0, function* () {
            const currentTime = yield this.getCurrentTime();
            if (this.events.onTimeUpdate) {
                this.events.onTimeUpdate(currentTime);
            }
        }), 1000); // 每 1 秒更新一次
    }
    stopProgressUpdater() {
        if (this.progressUpdateInterval !== null) {
            clearInterval(this.progressUpdateInterval);
            this.progressUpdateInterval = null;
            console.log("Progress updater stopped.");
        }
    }
    addSpotifyEventListeners() {
        if (!this.player) {
            console.error("Spotify 播放器尚未初始化");
            return;
        }
        this.player.addListener("player_state_changed", (state) => {
            var _a, _b, _c, _d;
            if (!state) {
                console.warn("No playback state available.");
                return;
            }
            const currentTime = state.position;
            const isPaused = state.paused;
            const trackEnded = state.track_window.next_tracks.length === 0 && (currentTime >= state.duration - 5000);
            // 更新進度
            (_b = (_a = this.events).onTimeUpdate) === null || _b === void 0 ? void 0 : _b.call(_a, currentTime);
            // 播放結束處理
            if (trackEnded) {
                console.log("Track ended.");
                (_d = (_c = this.events).onPlaybackEnded) === null || _d === void 0 ? void 0 : _d.call(_c); // 通知主控層播放結束
            }
            // 處理播放暫停與啟動進度更新
            if (isPaused) {
                this.stopProgressUpdater();
            }
            else {
                this.startProgressUpdater();
            }
        });
    }
}
exports.SpotifyPlayerStrategy = SpotifyPlayerStrategy;
