"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateMessages = void 0;
exports.updateMessages = {
    modalMessage: `
        歡迎使用 ChordSnap！<br><br> 

        我是網站負責人 Koma，本網站由知名平台Github託管且不會儲存任何資訊在官方後台請安心使用。<br><br>

        1.Spotify播放功能官方目前只開放給我少量名額，正積極爭取中，有興趣的可以提供Spotify Premium帳號先幫你加入List。<br><br>

        2.遇到小Bug就先儲存資料再重新整理試試，也麻煩通知我讓我修正。常使用的人也麻煩通知我，資料儲存方式有變更才能即時通知保存您的資料，謝謝您。<br><br>
        
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿<br>
        ＠推薦專欄：若 Spotify 審核通過，將開放專欄徵稿，歡迎創作者、音樂教師或愛好者投稿，分享自己的編曲手法、和弦運用或樂曲評論等內容。有興趣者歡迎聯繫！<br><br>

        <br>2024.11.28<br>
        komawang.77@gmail.com</a>

    `,
};
