"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DataStorageManager {
    constructor() {
        this.data = []; // 用來儲存全域資料的 array
    }
    static getInstance() {
        if (!DataStorageManager.instance) {
            DataStorageManager.instance = new DataStorageManager();
        }
        return DataStorageManager.instance;
    }
    updateData(id, newData) {
        const index = this.data.findIndex((item) => item.id === id);
        if (index !== -1) {
            this.data[index] = newData;
            console.log("Data updated:", newData);
        }
        else {
            this.data.push(newData);
            console.log("Data added:", newData);
        }
    }
    collectDataFromDOM() {
        const elements = document.querySelectorAll('[data-store]');
        elements.forEach((element) => {
            const id = element.id;
            const value = element.innerHTML.replace(/<br\s*\/?>/gi, '\n'); // 將 <br> 替換為 \n
            // const value = element.textContent || "";
            this.updateData(id, { id, value });
        });
        console.log("Data collected from DOM:", this.data);
    }
    applyDataToDOM() {
        const elements = document.querySelectorAll('[data-store]'); // 搜尋具有 data-store 屬性的元素
        elements.forEach((element) => {
            const id = element.id; // DOM element 的 id
            const dataItem = this.data.find((item) => item.id === id); // 在 data 中查找與 id 匹配的項目
            if (dataItem) {
                element.innerHTML = dataItem.value.replace(/\n/g, '<br>'); // 將 \n 恢復為 <br>
                // 只針對 data-store="sheetblock" 的元素觸發 blur 事件
                if (element.getAttribute("data-store") === "sheetblock") {
                    element.dispatchEvent(new Event("blur")); // 手動觸發 blur 事件
                }
                // element.textContent = dataItem.value; // 將 data 中的 value 回填到 DOM 元素
                console.log(`Element with id "${id}" updated with value: "${dataItem.value}"`);
                // formatText(htmlElement);
            }
            else {
                console.warn(`No matching data found for element with id: ${id}`);
            }
        });
    }
    // for download 
    getData() {
        this.collectDataFromDOM();
        return this.data;
    }
    clearData() {
        this.data = [];
        console.log("Data has been cleared.");
    }
}
exports.default = DataStorageManager.getInstance();
