"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebAudioPlayerStrategy = void 0;
class WebAudioPlayerStrategy {
    constructor() {
        this.audioContext = null;
        this.audioElement = null;
        this.sourceNode = null;
        this.gainNode = null;
        this.isInitialized = false;
        this.currentFile = null;
        this.events = {};
    }
    /**
     * 初始化播放器
     */
    init() {
        if (this.isInitialized) {
            console.log("WebAudioPlayerStrategy is already initialized.");
            return;
        }
        console.log("Initializing WebAudioPlayerStrategy...");
        // 延遲初始化 AudioContext，適配一些需要用戶交互的瀏覽器
        document.addEventListener("click", () => {
            if (!this.audioContext) {
                this.audioContext = new AudioContext();
                this.gainNode = this.audioContext.createGain();
                this.gainNode.gain.value = 0.6;
                console.log("AudioContext initialized on first interaction.");
            }
        }, { once: true });
        // 創建 <audio> 元素
        this.audioElement = document.createElement("audio");
        document.body.appendChild(this.audioElement);
        this.isInitialized = true;
        console.log("WebAudioPlayerStrategy initialized.");
    }
    checkInitialized() {
        if (!this.isInitialized) {
            this.init();
        }
    }
    /**
     * 確認是否準備好播放
     */
    isReadyToPlay() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                if (!this.audioElement) {
                    console.error("Audio element is not initialized.");
                    resolve(false);
                    return;
                }
                if (this.audioElement.readyState >= 3) {
                    resolve(true);
                }
                else {
                    this.audioElement.addEventListener("canplay", () => resolve(true), { once: true });
                }
            });
        });
    }
    /**
     * 上傳新的音頻文件
     */
    uploadNewTrack() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                const fileInput = document.createElement("input");
                fileInput.type = "file";
                fileInput.accept = ".mp3,.m4a,audio/*";
                fileInput.onchange = () => __awaiter(this, void 0, void 0, function* () {
                    var _a, _b;
                    const file = fileInput.files ? fileInput.files[0] : null;
                    if (file) {
                        console.log("New file selected:", file.name);
                        yield this.swapAudioSource(file);
                        // 等待获取 duration
                        const duration = yield this.getDuration();
                        // **通过事件通知曲目变更**
                        (_b = (_a = this.events).onTrackChange) === null || _b === void 0 ? void 0 : _b.call(_a, {
                            id: file.name,
                            name: file.name,
                            duration: duration, // 使用获取的时长值
                        });
                        console.log("Track updated with duration:", duration);
                        resolve(true);
                    }
                    else {
                        console.error("No file selected.");
                        resolve(false);
                    }
                });
                fileInput.click();
            });
        });
    }
    /**
     * 替換當前音頻源
     */
    swapAudioSource(file) {
        var _a;
        try {
            if (this.sourceNode) {
                this.sourceNode.disconnect();
                console.log("舊的音頻源已斷開");
            }
            const newAudioElement = document.createElement("audio");
            newAudioElement.src = URL.createObjectURL(file);
            document.body.appendChild(newAudioElement);
            (_a = this.audioElement) === null || _a === void 0 ? void 0 : _a.remove();
            this.audioElement = newAudioElement;
            this.currentFile = file.name;
            this.sourceNode = this.audioContext.createMediaElementSource(newAudioElement);
            this.sourceNode.connect(this.gainNode).connect(this.audioContext.destination);
            console.log("New audio source connected:", file.name);
            this.addAudioEventListeners();
        }
        catch (error) {
            console.error("Error occurred while swapping audio source:", error);
        }
    }
    /**
     * 添加事件監聽器
     */
    addAudioEventListeners() {
        if (!this.audioElement)
            return;
        this.audioElement.addEventListener("canplay", () => {
            console.log("New audio loaded and ready to play.");
        });
        this.audioElement.addEventListener("timeupdate", () => {
            var _a, _b;
            const currentTime = this.audioElement.currentTime * 1000; // 轉換為毫秒
            console.log(`By Web Audio：${currentTime}ms`);
            (_b = (_a = this.events).onTimeUpdate) === null || _b === void 0 ? void 0 : _b.call(_a, currentTime);
        });
        this.audioElement.addEventListener("ended", () => {
            var _a, _b;
            console.log("By Web Audio： END");
            (_b = (_a = this.events).onPlaybackEnded) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
    }
    /**
     * 獲取曲目信息
     */
    getTrackInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const duration = yield this.getDuration();
            return {
                id: this.currentFile || "unknown",
                name: this.currentFile || "Unknown Track",
                duration: duration,
            };
        });
    }
    /**
     * 獲取當前曲目時長
     */
    // async getDuration(): Promise<number> {
    //     if (this.audioElement) {
    //         const duration = this.audioElement.duration * 1000;
    //         this.events.onDurationChange?.(duration);
    //         return duration;
    //     }
    //     return 0;
    // }
    getDuration() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.audioElement)
                return 0;
            return new Promise((resolve) => {
                if (!this.audioElement) {
                    throw new Error("Audio element is not initialized.");
                }
                if (this.audioElement.readyState >= 3) { // 3 表示 `HAVE_FUTURE_DATA`，音頻已加載足夠的數據
                    resolve(this.audioElement.duration * 1000);
                }
                else {
                    this.audioElement.addEventListener("canplay", () => {
                        resolve(this.audioElement.duration * 1000);
                    }, { once: true } // 確保只監聽一次
                    );
                }
            });
        });
    }
    /**
     * 播放音頻
     */
    play() {
        if (this.audioElement) {
            this.audioElement
                .play()
                .then(() => {
                console.log(`Playing audio: ${this.currentFile}`);
            })
                .catch((error) => {
                console.error("Error occurred while trying to play audio:", error);
            });
        }
        else {
            console.log("Cannot play audio: Player is not ready or no file is loaded.");
        }
    }
    /**
     * 暫停播放
     */
    pause() {
        if (this.audioElement && this.gainNode) {
            // fadeout setting
            const fadeOutDuration = 0.5;
            const originalVolume = this.gainNode.gain.value;
            this.gainNode.gain.cancelScheduledValues(this.audioContext.currentTime);
            this.gainNode.gain.setValueAtTime(originalVolume, this.audioContext.currentTime);
            this.gainNode.gain.exponentialRampToValueAtTime(0.001, this.audioContext.currentTime + fadeOutDuration);
            // (when volume =0) -> pause & restore volume
            setTimeout(() => {
                this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);
                this.audioElement.pause();
                console.log("audio pause.");
                // Restore volume
                setTimeout(() => {
                    this.gainNode.gain.setValueAtTime(originalVolume, this.audioContext.currentTime);
                    console.log("音量已恢復到原始值");
                }, 50); // Delay 50
            }, fadeOutDuration * 1000 + 50);
            console.log(`Paused audio: ${this.currentFile}`);
        }
        else {
            console.log("Cannot pause audio: Player is not ready or audio is already paused.");
        }
    }
    /**
     * 設置音量
     */
    setVolume(volume) {
        if (this.gainNode) {
            const clampedVolume = Math.max(0, Math.min(1, volume));
            this.gainNode.gain.setValueAtTime(clampedVolume, this.audioContext.currentTime);
            console.log("Volume set to:", clampedVolume);
        }
    }
    /**
     * 跳轉到指定時間
     */
    seekTo(time) {
        if (this.audioElement) {
            this.audioElement.currentTime = time / 1000; // 毫秒轉秒
            console.log(`Seeked to ${time / 1000} seconds`);
        }
    }
}
exports.WebAudioPlayerStrategy = WebAudioPlayerStrategy;
