"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpotifyAPI = void 0;
class SpotifyAPI {
    constructor() {
        this.clientSecret = '5ba5b26256324190824902cfc8e1cf17';
        this.clientId = 'c03f1231a22244648357242f3ebd3198';
        this.redirectUri = 'https://www.chord-snap.com/callback';
        // IP 會浮動更改！！！！
        // this.redirectUri = process.env.NODE_ENV === 'production'
        //     ? process.env.REDIRECT_URI_PROD || 'https://localhost:3001/callback'
        //     : process.env.REDIRECT_URI_DEV || 'http://192.168.50.222:5001/callback';
        this.scopes = [
            'ugc-image-upload',
            'user-read-playback-state',
            'user-modify-playback-state',
            'user-read-currently-playing',
            'app-remote-control',
            'streaming',
            'playlist-read-private',
            'playlist-read-collaborative',
            'playlist-modify-private',
            'playlist-modify-public',
            'user-follow-modify',
            'user-follow-read',
            'user-read-playback-position',
            'user-top-read',
            'user-read-recently-played',
            'user-library-modify',
            'user-library-read',
            'user-read-email',
            'user-read-private'
        ].join(' ');
        this.container = document.createElement('div');
        this.init();
        this.checkAuthorizationCode();
    }
    // 初始化，創建按鈕
    init() {
        this.createUserProfileButton();
        this.createLogoutButton();
        this.createLoginButton();
    }
    createLoginButton() {
        const button = document.createElement('button');
        button.innerText = "登入 Spotify";
        button.onclick = () => this.authorizeAndExchangeToken();
        this.container.appendChild(button);
    }
    authorizeAndExchangeToken() {
        // const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirectUri)}&scope=${encodeURIComponent(this.scopes)}`;
        const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirectUri)}&scope=${encodeURIComponent(this.scopes)}&show_dialog=true`;
        localStorage.setItem('test', authUrl);
        // window.location.href = authUrl;
        const authWindow = window.open(authUrl, 'spotifyAuth', 'width=600,height=600');
        const authInterval = setInterval(() => {
            try {
                if (authWindow && authWindow.location.href.includes(this.redirectUri)) {
                    const urlParams = new URLSearchParams(authWindow.location.search);
                    const authCode = urlParams.get('code');
                    if (authCode) {
                        localStorage.setItem('spotify_auth_code', authCode);
                        authWindow.close();
                        clearInterval(authInterval);
                        this.exchangeCodeForToken(); // 自動交換 Token
                    }
                }
            }
            catch (error) {
                console.log(error);
                // 跨域訪問限制，忽略錯誤
            }
        }, 300);
    }
    // 建立授權 URL 並跳轉
    authorizePopup() {
        const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirectUri)}&scope=${encodeURIComponent(this.scopes)}`;
        console.log("Authorization URL:", authUrl);
        // 開啟新視窗進行授權
        const authWindow = window.open(authUrl, 'spotifyAuth', 'width=600,height=600');
        // 檢查新視窗的 URL 是否包含授權碼
        const authInterval = setInterval(() => {
            try {
                if (authWindow && authWindow.location.href.includes(this.redirectUri)) {
                    const urlParams = new URLSearchParams(authWindow.location.search);
                    const authCode = urlParams.get('code');
                    if (authCode) {
                        console.log("Authorization code found:", authCode);
                        localStorage.setItem('spotify_auth_code', authCode);
                        // 關閉新視窗並清除計時器
                        authWindow.close();
                        clearInterval(authInterval);
                    }
                }
            }
            catch (error) {
                // 跨域訪問限制，忽略錯誤
            }
        }, 1000); // 每秒檢查一次
    }
    // 檢查 URL 中是否有授權碼並儲存在 localStorage
    checkAuthorizationCode() {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        if (authCode) {
            console.log("Authorization code found:", authCode);
            localStorage.setItem('spotify_auth_code', authCode);
            const codeDisplay = document.createElement('p');
            codeDisplay.innerText = `Authorization Code: ${authCode}`;
            this.container.appendChild(codeDisplay);
            window.history.replaceState({}, document.title, this.redirectUri);
        }
        else {
            const storedAuthCode = localStorage.getItem('spotify_auth_code');
            if (storedAuthCode) {
                console.log("Using stored authorization code:", storedAuthCode);
                const codeDisplay = document.createElement('p');
                codeDisplay.innerText = `Stored Authorization Code: ${storedAuthCode}`;
                this.container.appendChild(codeDisplay);
            }
        }
    }
    // 交換授權碼為 access token
    exchangeCodeForToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const authCode = localStorage.getItem('spotify_auth_code');
            if (!authCode) {
                console.error("No authorization code found in localStorage.");
                return;
            }
            const tokenUrl = "https://accounts.spotify.com/api/token";
            const body = new URLSearchParams({
                grant_type: 'authorization_code',
                code: authCode,
                redirect_uri: this.redirectUri,
                client_id: this.clientId,
                client_secret: this.clientSecret,
            });
            try {
                console.log("Exchanging authorization code for access token...");
                const response = yield fetch(tokenUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: body.toString(),
                });
                if (!response.ok) {
                    throw new Error("Failed to exchange code for token");
                }
                const data = yield response.json();
                const accessToken = data.access_token;
                console.log("Access token received:", accessToken);
                localStorage.setItem('spotify_access_token', accessToken);
                const tokenDisplay = document.createElement('p');
                tokenDisplay.innerText = `Access Token: ${accessToken}`;
                this.container.appendChild(tokenDisplay);
            }
            catch (error) {
                console.error("Error exchanging code for token:", error);
            }
        });
    }
    // 獲取個人資訊
    getUserProfile() {
        return __awaiter(this, void 0, void 0, function* () {
            const accessToken = localStorage.getItem('spotify_access_token');
            console.log(accessToken);
            if (!accessToken) {
                console.error("No access token found in localStorage.");
                return;
            }
            const profileUrl = "https://api.spotify.com/v1/me";
            try {
                console.log("Fetching user profile...");
                const response = yield fetch(profileUrl, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                    },
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch user profile");
                }
                const data = yield response.json();
                console.log("User Profile:", data);
                const profileDisplay = document.createElement('pre');
                profileDisplay.innerText = JSON.stringify(data, null, 2);
                this.container.appendChild(profileDisplay);
            }
            catch (error) {
                console.error("Error fetching user profile:", error);
            }
        });
    }
    // 登出，清除 localStorage 資料
    logout() {
        localStorage.removeItem('spotify_auth_code');
        localStorage.removeItem('spotify_access_token');
        console.log("User logged out, localStorage cleared.");
        const logoutMessage = document.createElement('p');
        logoutMessage.innerText = "You have been logged out.";
        this.container.appendChild(logoutMessage);
    }
    createAuthorizeButton() {
        const button = document.createElement('button');
        button.innerText = "Authorize with Spotify";
        button.onclick = () => this.authorizePopup();
        this.container.appendChild(button);
    }
    // 創建交換 token 的按鈕
    createExchangeTokenButton() {
        const button = document.createElement('button');
        button.innerText = "Exchange Code for Token";
        button.onclick = () => this.exchangeCodeForToken();
        this.container.appendChild(button);
    }
    // 創建獲取個人資訊的按鈕
    createUserProfileButton() {
        const button = document.createElement('button');
        button.innerText = "Get User Profile";
        button.onclick = () => this.getUserProfile();
        this.container.appendChild(button);
    }
    // 創建登出按鈕
    createLogoutButton() {
        const button = document.createElement('button');
        button.innerText = "Logout";
        button.onclick = () => this.logout();
        this.container.appendChild(button);
    }
    // 導出 container 元素
    getElement() {
        return this.container;
    }
}
exports.SpotifyAPI = SpotifyAPI;
