"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainPlayer = void 0;
const WebAudioStrategy2_1 = require("./WebAudioStrategy2");
const SpotifyStrategy2_1 = require("./SpotifyStrategy2");
class MainPlayer {
    constructor(proxy, defaultStrategy = "audio") {
        this.animationFrameId = null;
        this.proxy = proxy;
        // 初始化策略
        this.audioPlayer = new WebAudioStrategy2_1.WebAudioPlayerStrategy();
        this.spotifyPlayer = new SpotifyStrategy2_1.SpotifyPlayerStrategy();
        this.currentStrategy = defaultStrategy === "audio" ? this.audioPlayer : this.spotifyPlayer;
        // 設置回調並初始化當前策略
        this.setupStrategyCallbacks(this.currentStrategy);
        this.currentStrategy.init();
    }
    /**
     * 設置當前策略
     */
    setStrategy(strategy) {
        if (this.currentStrategy === strategy) {
            console.log("當前策略未改變");
            return;
        }
        this.clearStrategyCallbacks(this.currentStrategy);
        this.currentStrategy = strategy;
        this.setupStrategyCallbacks(this.currentStrategy);
        this.currentStrategy.checkInitialized();
    }
    /**
     * 初始化策略事件回調
     */
    setupStrategyCallbacks(strategy) {
        const { events } = strategy;
        events.onTimeUpdate = this.handleTimeUpdate.bind(this);
        events.onPlaybackEnded = this.handlePlaybackEnded.bind(this);
        // events.onDurationChange = this.handleDurationUpdate.bind(this);
        events.onTrackChange = this.handleTrackChange.bind(this);
        console.log(`已設置事件回調：${strategy.constructor.name}`);
    }
    /**
     * 清理策略事件回調
     */
    clearStrategyCallbacks(strategy) {
        const { events } = strategy;
        events.onTimeUpdate = undefined;
        events.onPlaybackEnded = undefined;
        events.onDurationChange = undefined;
        events.onTrackChange = undefined;
        console.log(`已清理事件回調：${strategy.constructor.name}`);
    }
    /**
     * 播放進度更新回調
     */
    handleTimeUpdate(currentTime) {
        console.log(`Time Updated: ${currentTime}`);
        this.proxy.setState("currentTime", currentTime);
    }
    /**
     * 播放結束回調
     */
    handlePlaybackEnded() {
        console.log("Playback Ended");
        this.proxy.setState("isPaused", true);
    }
    /**
     * 曲目時長更新回調
     */
    // private handleDurationUpdate(duration: number): void {
    //     console.log(`Duration Updated: ${duration}`);
    //     this.proxy.setState("duration", duration);
    // }
    /**
     * 曲目變更回調
     */
    handleTrackChange(trackInfo) {
        console.log("handleTrackChange Track Changed: ", trackInfo);
        this.proxy.setState("trackInfo", trackInfo);
        this.proxy.setState("trackName", trackInfo.name);
        this.proxy.setState("duration", Number(trackInfo.duration));
    }
    /**
     * 切換播放模式
     */
    toggleMode(mode) {
        const message = document.createElement('div');
        message.className = 'toggle-mode-message';
        const modal = document.createElement('div');
        modal.id = 'toggleModeModal';
        modal.style.position = 'fixed';
        modal.style.top = '0';
        modal.style.left = '0';
        modal.style.width = '100%';
        modal.style.height = '100%';
        modal.style.display = 'flex';
        modal.style.justifyContent = 'center';
        modal.style.alignItems = 'center';
        modal.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
        modal.style.zIndex = '1000';
        modal.style.visibility = 'hidden';
        modal.style.opacity = '0';
        modal.style.transition = 'visibility 0.3s, opacity 0.3s';
        // 创建模态框内容
        const modalContent = document.createElement('div');
        modalContent.style.position = 'relative';
        modalContent.style.backgroundColor = 'white';
        modalContent.style.padding = '20px';
        modalContent.style.borderRadius = '8px';
        modalContent.style.textAlign = 'center';
        modalContent.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
        modalContent.style.width = '300px';
        document.body.appendChild(message);
        if (mode === 'spotify' || (mode === undefined && this.currentStrategy instanceof WebAudioStrategy2_1.WebAudioPlayerStrategy)) {
            this.setStrategy(this.spotifyPlayer);
            message.textContent = "Spotify Mode";
            console.log("切換到 Spotify 播放模式");
        }
        else {
            this.setStrategy(this.audioPlayer);
            message.textContent = "Audio Mode";
            console.log("切換到 WebAudio 播放模式");
        }
        modalContent.appendChild(message);
        // 将内容添加到模态框
        modal.appendChild(modalContent);
        document.body.appendChild(modal); // 将模态框插入到 body
        // 显示模态框
        modal.style.visibility = 'visible';
        modal.style.opacity = '1';
        // 设置定时器，0.5 秒后自动隐藏并移除模态框
        setTimeout(() => {
            modal.style.visibility = 'hidden';
            modal.style.opacity = '0';
            setTimeout(() => {
                document.body.removeChild(modal); // 确保动画完成后移除模态框
            }, 300); // 与动画持续时间一致
        }, 500);
    }
    update() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const isUpdated = yield this.currentStrategy.uploadNewTrack();
                if (isUpdated) {
                    this.pause();
                    console.log("Track updated successfully.");
                }
                else {
                    console.error("Failed to update track.");
                }
            }
            catch (error) {
                console.error("An error occurred while updating the track:", error);
            }
        });
    }
    /**
     * 開始播放
     */
    play() {
        this.currentStrategy.play();
        this.proxy.setState("isPaused", false);
        console.log("正在播放...");
    }
    /**
     * 暫停播放
     */
    pause() {
        this.currentStrategy.pause();
        this.proxy.setState("isPaused", true);
        console.log("播放已暫停");
    }
    /**
     * 設置音量
     */
    setVolume(volume) {
        this.currentStrategy.setVolume(volume);
        console.log(`音量設置為 ${volume}`);
    }
    /**
     * 跳轉到指定時間
     */
    seekTo(time) {
        this.currentStrategy.seekTo(time);
        console.log(`跳轉到 ${time}`);
    }
}
exports.MainPlayer = MainPlayer;
