"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// 
require("./index");
require("./data/DataStorageManager");
require("./components/header/DownloadUpload");
require("./data/SpotifyAuthManager");
require("./styles");
// 
require("nouislider/dist/nouislider.css");
// 引入 Bootstrap 的 CSS
require("bootstrap/dist/css/bootstrap.min.css");
require("bootstrap");
// shoelace-style
require("@shoelace-style/shoelace/dist/components/dropdown/dropdown.js");
require("@shoelace-style/shoelace/dist/components/button/button.js");
require("@shoelace-style/shoelace/dist/components/menu-item/menu-item.js");
require("@shoelace-style/shoelace/dist/components/menu/menu.js");
require("@shoelace-style/shoelace/dist/components/icon-button/icon-button.js");
require("@shoelace-style/shoelace/dist/components/icon/icon");
require("@shoelace-style/shoelace/dist/themes/light.css");
require("@shoelace-style/shoelace/dist/components/range/range.js");
const base_path_js_1 = require("@shoelace-style/shoelace/dist/utilities/base-path.js");
(0, base_path_js_1.setBasePath)('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0-beta.83/dist');
