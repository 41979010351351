"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTextForChord = formatTextForChord;
exports.formatTextForLable = formatTextForLable;
// for chord : 3 size 
function formatTextForChord(element) {
    var _a;
    let text = ((_a = element.textContent) === null || _a === void 0 ? void 0 : _a.trim()) || "";
    text = text.replace(/maj/gi, "∆");
    text = text.replace(/dim/gi, "o");
    text = text.replace(/m7b5/gi, "ø7");
    const romanNumeralRegex = /^([b#]?)(VII|VI|IV|V|III|II|I|[1-7])(.*)$/;
    const match = text.match(romanNumeralRegex);
    // romanNumer -> Chord 
    if (match) {
        const [_, accidental, romanPart, rest] = match;
        let formattedText = "";
        if (accidental) {
            formattedText += `<span class="chord-larger-text-top">${accidental}</span>`;
        }
        formattedText += `<span class="chord-largest-text-number">${romanPart}</span>`;
        if (rest && rest.trim().length > 0) {
            formattedText += `<span class="chord-larger-text">${rest}</span>`;
        }
        element.innerHTML = formattedText;
        return;
    }
    // Chord 
    if (text.length > 0) {
        // Frist word Always largest
        let formattedText = `<span class="chord-largest-text">${text.charAt(0)}</span>`;
        if (text.length > 1) {
            const secondChar = text.charAt(1);
            const rest = text.slice(2);
            // "5", "6", "7", "11","13"
            if (["5", "6", "7", "9", "1"].includes(secondChar)) {
                // Check "11"  "13"
                if (rest.startsWith("1")) {
                    formattedText += `<span class="chord-larger-text">${secondChar + "1"}</span>`;
                    formattedText += `<span class="chord-smallest-text">${rest.slice(1)}</span>`;
                }
                else if (rest.startsWith("3")) {
                    formattedText += `<span class="chord-larger-text">${secondChar + "3"}</span>`;
                    formattedText += `<span class="chord-smallest-text">${rest.slice(1)}</span>`;
                }
                else {
                    formattedText += `<span class="chord-larger-text">${secondChar}</span>`;
                    formattedText += `<span class="chord-smallest-text">${rest}</span>`;
                }
            }
            else if (["b", "m", "#"].includes(secondChar)) {
                if (secondChar === "m" && rest.length > 0) {
                    if (/^\d/.test(rest)) {
                        // "m" + "number"，m -> larger-text
                        formattedText += `<span class="chord-larger-text">${secondChar}</span>`;
                        formattedText += `<span class="chord-smallest-text">${rest}</span>`;
                    }
                    else {
                        // "m" + "other"，m -> smallest-text
                        formattedText += `<span class="chord-smallest-text">${secondChar + rest}</span>`;
                    }
                }
                else {
                    formattedText += `<span class="chord-larger-text">${secondChar}</span>`;
                    formattedText += `<span class="chord-smallest-text">${rest}</span>`;
                }
            }
            else {
                // smallest-text
                formattedText += `<span class="chord-smallest-text">${text.slice(1)}</span>`;
            }
        }
        // Update DOM
        element.innerHTML = formattedText;
    }
}
// for Lable : 2 size & 2 condition
function formatTextForLable(element) {
    const text = element.textContent || "";
    let formattedText = "";
    if (text.length > 0) {
        if (text.length <= 2) {
            formattedText = `<span class="section-label-largest-text">${text}</span>`;
        }
        else {
            formattedText = `<span class="section-label-smallest-text">${text}</span>`;
        }
        element.innerHTML = formattedText;
    }
}
// ^^^^^^^^^ check! 2024/11/19 ^^^^^^^^
