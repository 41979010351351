"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// PageNavigator.ts
class PageNavigator {
    constructor(container, totalPages, onSwitchPage) {
        this.container = container;
        this.totalPages = totalPages;
        this.onSwitchPage = onSwitchPage;
        this.currentPage = 1; // default
        this.createNavigation();
        this.handleButtonVisibility();
    }
    createNavigation() {
        const navigationContainer = document.createElement('div');
        navigationContainer.classList.add('page-navigation');
        //  Previous  Buttom "<" 
        const prevButton = document.createElement('button');
        prevButton.textContent = '<';
        prevButton.classList.add('prev-button');
        prevButton.addEventListener('click', () => this.goToPreviousPage());
        navigationContainer.appendChild(prevButton);
        // "Current page" Text display 
        const currentPageDisplay = document.createElement('span');
        currentPageDisplay.classList.add('current-page-display');
        currentPageDisplay.textContent = `Page ${this.currentPage}`;
        navigationContainer.appendChild(currentPageDisplay);
        // Next Buttom "<" 
        const nextButton = document.createElement('button');
        nextButton.textContent = '>';
        nextButton.classList.add('next-button');
        nextButton.addEventListener('click', () => this.goToNextPage());
        navigationContainer.appendChild(nextButton);
        this.container.appendChild(navigationContainer);
    }
    goToPreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.onSwitchPage(this.currentPage);
            this.handleButtonVisibility();
        }
    }
    goToNextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.onSwitchPage(this.currentPage);
            this.handleButtonVisibility();
        }
    }
    handleButtonVisibility() {
        const prevButton = this.container.querySelector('.prev-button');
        const currentPageDisplay = this.container.querySelector('.current-page-display');
        const nextButton = this.container.querySelector('.next-button');
        // Hide the "<" button on the first page
        if (this.currentPage === 1) {
            prevButton.style.opacity = '0';
            prevButton.style.pointerEvents = 'none';
        }
        else {
            prevButton.style.opacity = '1';
            prevButton.style.pointerEvents = 'auto';
        }
        // Hide the ">" button on the last page
        if (this.currentPage === this.totalPages) {
            nextButton.style.opacity = '0';
            nextButton.style.pointerEvents = 'none';
        }
        else {
            nextButton.style.opacity = '1';
            nextButton.style.pointerEvents = 'auto';
        }
        currentPageDisplay.textContent = `Page ${this.currentPage}`;
    }
}
exports.default = PageNavigator;
// ^^^^^^^^^ check! 2024/11/19 ^^^^^^^^
