"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerProxy = void 0;
class PlayerProxy {
    constructor(initialState) {
        this.listeners = new Map();
        this.state = new Proxy(initialState, {
            set: (target, property, value) => {
                target[property] = value;
                this.notifyListeners(property, value); // 通知監聽器
                console.log(`[PlayerProxy] State updated: ${String(property)} = ${value}`);
                return true;
            },
        });
    }
    setState(key, value) {
        this.state[key] = value;
    }
    getState(key) {
        return this.state[key];
    }
    on(key, callback) {
        if (!this.listeners.has(key)) {
            this.listeners.set(key, []);
        }
        this.listeners.get(key).push(callback);
    }
    off(key, callback) {
        if (this.listeners.has(key)) {
            const callbacks = this.listeners.get(key).filter(cb => cb !== callback);
            this.listeners.set(key, callbacks);
        }
    }
    notifyListeners(key, value) {
        if (this.listeners.has(key)) {
            this.listeners.get(key).forEach(callback => callback(value));
        }
    }
}
exports.PlayerProxy = PlayerProxy;
