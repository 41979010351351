// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 確保 header 佔滿整個螢幕寬度 */
header.app-header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.navbar-dropdown {
    width: 100%;
    display: flex;
    justify-content: center;
}

.navbar-button::part(base) {
    width: 100px;
    border: none;
    /* 移除邊框 */
    /* 設定按鈕寬度 */
}

.navbar-dropdown::part(panel) {
    width: 200px;
    max-height: 300px;
    overflow-y: auto;
}

.navbar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* 設定選項距離 */
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,oBAAoB;IACpB,sBAAsB;AAC1B","sourcesContent":["/* 確保 header 佔滿整個螢幕寬度 */\nheader.app-header {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.navbar-dropdown {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.navbar-button::part(base) {\n    width: 100px;\n    border: none;\n    /* 移除邊框 */\n    /* 設定按鈕寬度 */\n}\n\n.navbar-dropdown::part(panel) {\n    width: 200px;\n    max-height: 300px;\n    overflow-y: auto;\n}\n\n.navbar-item {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    /* 設定選項距離 */\n    padding: 0.5rem 1rem;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
