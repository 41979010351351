"use strict";
// import @shoelace-style
Object.defineProperty(exports, "__esModule", { value: true });
const DownloadUpload_1 = require("./DownloadUpload");
const SpotifyAuthManager_1 = require("../../data/SpotifyAuthManager");
const PDFExporter_1 = require("./PDFExporter");
const spotifyAPI = new SpotifyAuthManager_1.SpotifyAPI();
class NavbarController {
    constructor(navbarElement) {
        this.navbarElement = navbarElement;
    }
    init() {
        this.renderNavbar();
        this.setupEventListeners();
    }
    renderNavbar() {
        // 只實例化按鈕和菜單結構，樣式由外部 CSS 文件控制
        this.navbarElement.innerHTML = `
    <sl-dropdown class="navbar-dropdown" placement="bottom">
    <sl-button slot="trigger" class="navbar-button" caret >
    </sl-button>
    <sl-menu class="navbar-menu">
      <sl-menu-item id="downloadButton" class="navbar-item">Save Project</sl-menu-item>
      <sl-menu-item id="uploadButton" class="navbar-item">Open Project</sl-menu-item>
      <sl-menu-item id="PDFButton" class="navbar-item">PDF Download</sl-menu-item>
      <sl-menu-item id="loginButton" class="navbar-item">Login Spotify</sl-menu-item>
    </sl-menu>
    </sl-dropdown>
    `;
    }
    // <sl-menu-item id="loginButton" class="navbar-item">Login Spotify</sl-menu-item>
    setupEventListeners() {
        const uploadButton = this.navbarElement.querySelector('#uploadButton');
        uploadButton === null || uploadButton === void 0 ? void 0 : uploadButton.addEventListener('click', () => {
            (0, DownloadUpload_1.uploadData)();
            console.log('Upload button clicked');
        });
        const downloadButton = this.navbarElement.querySelector('#downloadButton');
        downloadButton === null || downloadButton === void 0 ? void 0 : downloadButton.addEventListener('click', () => {
            (0, DownloadUpload_1.downloadData)();
            console.log('Download button clicked');
        });
        let isAuthorized = false;
        const loginButton = this.navbarElement.querySelector('#loginButton');
        loginButton === null || loginButton === void 0 ? void 0 : loginButton.addEventListener('click', () => {
            if (!isAuthorized) {
                spotifyAPI.authorizeAndExchangeToken();
                console.log('Login button clicked - Authorizing...');
                isAuthorized = true; // 授權成功後改變狀態
                loginButton.textContent = "Login out Spotify";
            }
            else {
                spotifyAPI.logout();
                console.log('Login button clicked - Fetching profile...');
                isAuthorized = false; // 重置狀態以便於下一次切換
                loginButton.textContent = " Login Spotify ";
            }
        });
        const PDFButton = this.navbarElement.querySelector('#PDFButton');
        const exporter = new PDFExporter_1.PDFExporter();
        if (PDFButton) {
            PDFButton.addEventListener('click', () => {
                exporter.export();
            });
        }
        else {
            console.error('PDFButton not found in navbar.');
        }
    }
}
exports.default = NavbarController;
